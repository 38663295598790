import React from 'react';

const TechLife = () =>{
    return (
      <div className={'techLife'} id='about'>
        <div className={'tech'} >
          About Me
        </div>
  
        <div className={'life'} >
          I am a Software Engineer From Chicago.  I started off building frontend web applications, mostly using Reactjs.
          Today's workflow consist of backend development, infrastructure, database, and cloud computing.
        </div>
        <div className={'life'} >
          My journey into technology has been one of the best experiences in my life.  I love the innovation, dedication, and networking.
          I believe that it is important to create leaders in business and technology from different communities.  
          Throughout my journey I have met a lot of people.  One of the most motivating things that I have experienced is the women who were advocating for women in tech.  It's an empowering experience that boosted my confidence and eased my fear of failing.
        </div>
        <div className={'life'} >
          I am a huge advocate for <span className={'wit'}>WOMEN IN TECHNOLOGY</span> and diversity and inclusion.
        </div>
      </div>
    );
};

export default TechLife;
